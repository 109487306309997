<template>
  <div id="sizes-list">
    <!-- app drawer -->
    <sizes-list-add-new
      v-model="isAddNewSizeSidebarActive"
      @refetch-data="fetchSizes"
    ></sizes-list-add-new>

    <!-- sizes total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in sizesTotalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title> Sizes </v-card-title>
      <v-divider class="mt-4"></v-divider>

      <!-- actions -->

      <!-- <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            placeholder="Select Criteria"
            :items="planOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
      </v-row> -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="sizes-search me-3 mb-4"
        >
        </v-text-field> -->

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewSizeSidebarActive = !isAddNewSizeSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Size</span>
          </v-btn>
          <!-- <v-btn
            color="secondary"
            outlined
            class="mb-4"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn> -->
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="sizesListTable"
        :options.sync="options"
        :server-items-length="totalSizesListTable"
        :loading="loading"
        @refetch-data="fetchSizes"
      >
        <!-- name EN -->
        <template #[`item.name_en`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-size-view', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name_en }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- name AR -->
        <template #[`item.name_ar`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.name_ar }}</span>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-size-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <!-- <v-list-item
                link
                @click="handleDelete(item.id)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
mdiAccountOutline,
mdiDeleteOutline,
mdiDotsVertical,
mdiExportVariant,
mdiFileDocumentOutline,
mdiPlus,
mdiSquareEditOutline
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import Swal from 'sweetalert2'
import sizesStoreModule from '../sizesStoreModule'
import SizesListAddNew from './SizesListAddNew.vue'
import useSizesList from './useSizesList'

export default {
  components: {
    SizesListAddNew,
  },
  setup(props, { emit }) {
    const SIZES_APP_STORE_MODULE_NAME = 'app-sizes'

    // Register module
    if (!store.hasModule(SIZES_APP_STORE_MODULE_NAME)) {
      store.registerModule(SIZES_APP_STORE_MODULE_NAME, sizesStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SIZES_APP_STORE_MODULE_NAME)) store.unregisterModule(SIZES_APP_STORE_MODULE_NAME)
    })
    const {
      sizesListTable,
      tableColumns,
      searchQuery,
      totalSizesListTable,
      loading,
      options,
      sizesTotalLocal,
      selectedRows,

      fetchSizes,
    } = useSizesList()

    const isAddNewSizeSidebarActive = ref(false)

    const planOptions = [
      { title: 'Name (En)', value: 'name_en' },
      { title: 'Name (Ar)', value: 'name_ar' },
    ]

    const handleDelete = id => {
      Swal.fire({
        title: 'Are you sure you want to delete this item?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-sizes/deleteSize', id)
            .then(() => {
              sizesListTable.value = sizesListTable.value.filter(item => item.id !== id)
              Swal.fire('Deleted!', 'entry has been deleted.', 'success')
            })
            .catch(response => {
              console.error(response)
            })
        }
      })
    }

    return {
      sizesListTable,
      tableColumns,
      searchQuery,
      totalSizesListTable,
      loading,
      options,
      sizesTotalLocal,
      isAddNewSizeSidebarActive,
      selectedRows,
      planOptions,
      avatarText,
      fetchSizes,
      handleDelete,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
