import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSizes() {
      return new Promise((resolve, reject) => {
        axios

          .get('/sizes')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchSize(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios

          .get(`/sizes/view/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addSize(ctx, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/sizes/create',
          data,
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    editSize(ctx, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/sizes/update',
          data,
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteSize(_ctx, _id) {
      const data = { id: _id }

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/sizes/delete',
          data,
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
