<template>
  <v-navigation-drawer
    :value="isAddNewSizeSidebarActive"
    :permanent="isAddNewSizeSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-size-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Size</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-size-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-text-field
            v-model="sizeData.name_en"
            outlined
            dense
            :rules="[validators.required]"
            label="Size Name EN"
            placeholder="1/4 chicken leg"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="sizeData.name_ar"
            :rules="[validators.required]"
            outlined
            dense
            label="Size Name AR"
            placeholder="1/4 فخذة دجاج"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-btn
            color="primary"
            class="me-3"
            type="submit"
          >
            Add
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetSizeData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { imageValidator, integerValidator, required } from '@core/utils/validation'

import { mdiClipboardFileOutline, mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  model: {
    prop: 'isAddNewSizeSidebarActive',
    event: 'update:is-add-new-size-sidebar-active',
  },
  props: {
    isAddNewSizeSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankSizeData = {
      name_en: '',
      name_ar: '',
    }

    const isError = ref(false)
    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const sizeData = ref(JSON.parse(JSON.stringify(blankSizeData)))
    const resetSizeData = () => {
      sizeData.value = JSON.parse(JSON.stringify(blankSizeData))
      resetForm()
      emit('update:is-add-new-size-sidebar-active', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        store
          .dispatch('app-sizes/addSize', sizeData.value)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-size-sidebar-active', false)
          })
          .catch(error => {
            isError.value = true
            console.error(error)
          })

        resetSizeData()
      } else {
        validate()
      }
    }

    const handleAler = () => {
      isError.value = false
    }

    return {
      resetSizeData,
      form,
      onSubmit,
      countries,
      sizeData,
      valid,
      validate,
      isError,
      handleAler,

      // validation
      validators: {
        integerValidator,
        required,
        imageValidator,
      },
      icons: {
        mdiClose,
        mdiClipboardFileOutline,
      },
    }
  },
}
</script>
